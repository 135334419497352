<template>
  <el-select
    v-model="inputData"
    default-first-option
    multiple
    clearable
    collapse-tags
    collapse-tags-tooltip
    :placeholder="$t('Выберите значение')"
    class="filter-select"
    @change="applyFilter">
    <el-option
      v-for="item in selectList"
      :key="item.id"
      :label="item.title"
      :value="item.id.toString()" />
  </el-select>
</template>

<script setup>
import { computed, ref } from 'vue'
const emit = defineEmits(['applyFilter'])
const props = defineProps({
  selectList: {
    type: Array,
    default: () => [{title: 'test', id: '2'}, {title: 'test', id: '1'}],
    required: false
  }
})
const inputData = ref('')
const applyFilter = (val) => {
  emit('applyFilter', {
    eq: val,
  })
}
</script>

<style lang="scss">
  .el-select.filter-select .el-select__tags {
    margin-left: 7px;
    &-text {
      justify-content: start;
    }
  }
</style>
